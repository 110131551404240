
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Customer, MultiChoiceCard, MultiChoiceGiftCard, DotationOperation } from '@/models'

import { Form } from 'element-ui'

import { useUserStore } from '@/stores/user'

@Component({
  name: 'MultiChoiceCardDialog',
  components: {
    MultiChoiceCardDistributionsForm: () => import('@/components/MultiChoiceCardDistributionsForm/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private operation!: DotationOperation

  private userStore: any = useUserStore()

  private tempData: MultiChoiceCard = new MultiChoiceCard({
    customer: new Customer({ id: '' })
  })
  private loading = false

  private multiChoiceGiftCards: MultiChoiceGiftCard[] = []
  private multiChoiceGiftCardsLoading = false

  private modeMultiChoiceCardDistribution = false
  private multiChoiceCardDistributions: any[] = []

  private typeSearch = 'email'
  customers: Customer[] = []
  customersLoading = false

  get rules(): any {
    return {
      "customer.id": [
        { required: !this.modeMultiChoiceCardDistribution, message: 'La sélection d\'un client est requis', trigger: 'blur' }
      ],
      "multiChoiceGiftCard.id": [
        { required: !this.modeMultiChoiceCardDistribution, message: 'La carte multi-choix est requis', trigger: 'blur' }
      ],
      amount: [
        { required: !this.modeMultiChoiceCardDistribution, message: 'Le montant de la carte multi-choix est requis', trigger: 'blur' }
      ]
    }
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) this.handleCreate()
  }

  get caretaker() {
    return this.userStore.model.manageable
  }

  get multiChoiceGiftCardId(): string {
    return this.tempData?.multiChoiceGiftCard?.id || ''
  }

  get multiChoiceCardDistributionsTotal(): number {
    if (this.multiChoiceCardDistributions.length > 1) {
      return this.multiChoiceCardDistributions.slice(0, -1).reduce((acc: number, cur: any): number => {
        return (cur.quantity * cur.amount) + acc;
      }, 0);
    } else return 0
  }

  get canGiveMultiChoiceCard(): boolean {
    return this.operation.preIssue && this.operation.totalIssuedAmount > 0 || !this.operation.preIssue
  }

  created() {
    this.getMultiChoiceGiftCards()
  }

  async getCustomers(query: string) {
    this.customersLoading = true
    const { data } = await Customer
      .where({ [this.typeSearch]: { prefix: query } })
      .all()

    this.customers = data
    this.customersLoading = false
  }

  private async getMultiChoiceGiftCards() {
    this.multiChoiceGiftCardsLoading = true

    const { data } =
      await MultiChoiceGiftCard
        .page(1)
        .per(999)
        .all()

    if (data.length > 0) this.$emit('loaded')

    this.multiChoiceGiftCards.push(...data)

    this.multiChoiceGiftCardsLoading = false
  }

  handleCustomerChange(customerId: string) {
    const customer = this.customers.find(e => e.id === customerId)
    if (customer) this.tempData.customer = customer
  }

  private handleSelectMultiChoiceGiftCard(multiChoiceGiftCardId: string) {
    const multiChoiceGiftCard = this.multiChoiceGiftCards.find(e => e.id === multiChoiceGiftCardId)
    if (multiChoiceGiftCard) this.tempData.multiChoiceGiftCard = multiChoiceGiftCard
  }

  private handleCreate() {
    this.resetTempData()
    this.modeMultiChoiceCardDistribution = !this.canGiveMultiChoiceCard
    this.$nextTick(() => {
      (this.$refs.multiChoiceCardForm as Form).clearValidate()
    })
  }

  async create() {
    (this.$refs.multiChoiceCardForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {
        try {
          if (this.modeMultiChoiceCardDistribution) {
            let cardDistributions = this.multiChoiceCardDistributions.slice(0, -1)
            const data = await this.operation.preIssueCards(cardDistributions)
            this.$emit('issued', data)
            this.$notify({
              title: 'Succès',
              message: 'Suivez la progression de l\'émission',
              type: 'success',
              duration: 2000
            })
          } else if (this.tempData.customer) {
            await this.operation.give(
              this.tempData.multiChoiceGiftCard.id,
              this.tempData.customer.id,
              this.tempData.amount,
              this.tempData.scheduledAt as string | undefined
            )
            this.$notify({
              title: 'Succès',
              message: 'Carte multi-choix offerte',
              type: 'success',
              duration: 2000
            })
          }
          this.$emit('close')
        } catch (err: any) {
          for (const error of err?.response?.data?.errors) {
            this.$notify({
              title: 'Erreur',
              message: error.detail,
              type: 'error',
              duration: 2000
            })
            await this.sleep(50)
          }
        }
      }
      this.loading = false
    })
  }

  resetTempData() {
    this.tempData = new MultiChoiceCard({
      source: this.caretaker,
      dotationOperation: this.operation,
      customer: new Customer({ id: '' }),
      scheduledAt: null
    })
  }

  private addMultiChoiceCardDistribution(multiChoiceCardDistribution: any) {
    this.multiChoiceCardDistributions.push(multiChoiceCardDistribution)
  }

  private selectMultiChoiceGiftCard(index: number, multiChoiceGiftCardId: string) {
    this.multiChoiceCardDistributions[index].multi_choice_gift_card_id = multiChoiceGiftCardId
  }

  private async deleteMultiChoiceCardDistribution(index: number) {
    this.multiChoiceCardDistributions.splice(index, 1)
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
