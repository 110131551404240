
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CsvFormatInfo from './CsvFormatInfo.vue'
import DirectUpload from '@/components/DirectUpload/index.vue'

@Component({
  name: 'FileUploadZone',
  components: {
    CsvFormatInfo,
    DirectUpload
  }
})
export default class FileUploadZone extends Vue {
  @Prop({ required: true }) readonly fileList!: any[]
  @Prop({ required: true }) readonly isParsingCsv!: boolean
  @Prop({ required: true }) readonly isUploading!: boolean

  @Emit('upload')
  handleUpload(file: any) {
    return file
  }
}
