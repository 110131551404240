
import { Component, Vue, Prop } from 'vue-property-decorator'
import ImportErrors from './ImportErrors.vue'

interface ImportFail {
  row_number: number;
  errors: string[];
}

@Component({
  name: 'ImportResults',
  components: {
    ImportErrors
  }
})
export default class ImportResults extends Vue {
  @Prop({ required: true }) readonly successCount!: number
  @Prop({ required: true }) readonly failsCount!: number
  @Prop({ required: true }) readonly fails!: ImportFail[]

  get resultIcon(): string {
    return this.failsCount === 0 && this.successCount > 0 ? 'success' : 'warning'
  }

  get resultSubtitle(): string {
    const total = this.successCount + this.failsCount
    return `${total} carte(s) traitée(s)`
  }
}
