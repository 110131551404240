
import { Component, Vue, Prop } from 'vue-property-decorator'

interface ImportFail {
  row_number: number;
  errors: string[];
}

interface JsonApiError {
  code?: string;
  status?: string;
  title?: string;
  detail?: string;
  source?: {
    pointer?: string;
  };
  meta?: {
    attribute?: string;
    message?: string;
    code?: string;
    line_number?: number;
    details?: string;
  };
}

@Component({
  name: 'ImportErrors'
})
export default class ImportErrors extends Vue {
  @Prop({ required: true }) readonly fails!: ImportFail[]

  private activeNames: string[] = ['errors']
  private currentPage = 1
  private readonly pageSize = 3

  get paginatedErrors(): ImportFail[] {
    const start = (this.currentPage - 1) * this.pageSize
    const end = this.currentPage * this.pageSize
    return this.fails.slice(start, end)
  }

  handleErrorPageChange(page: number): void {
    this.currentPage = page
  }

  copyErrorToClipboard(fail: ImportFail): void {
    const text = `${this.$t('common.line')} ${fail.row_number}:\n${fail.errors.join('\n')}`
    navigator.clipboard.writeText(text).then(() => {
      this.$notify({
        title: String(this.$t('common.success')),
        message: String(this.$t('gifts.uploadDialog.errorCopied')),
        type: 'success',
        duration: 2000
      })
    })
  }

  private formatErrorMessage(error: string | JsonApiError): string {
    if (typeof error === 'string') {
      return error
    }

    try {
      const [errorCode, lineNumber, jsonDataStr] = error.meta?.code?.split(';') || []

      if (!errorCode || !lineNumber) {
        return String(error.detail || error.title || error.meta?.message || this.$t('gifts.validation.errors.unknown'))
      }

      let jsonData = null
      try {
        const cleanJsonStr = jsonDataStr.replace(/=>/g, ':')
        jsonData = JSON.parse(cleanJsonStr)
      } catch (e) {
        console.log('Impossible de parser le JSON:', e)
      }

      if (errorCode.startsWith('MISSING_')) {
        const field = errorCode.replace('MISSING_', '').toLowerCase()
        return String(this.$t('gifts.validation.errors.field_required', { field }))
      }

      const errorKey = errorCode.toLowerCase()
      const translationKey = `gifts.validation.errors.${errorKey}`

      if (this.$te(translationKey)) {
        return String(this.$t(translationKey))
      }

      return String(jsonData?.errors || error.detail || error.title || error.meta?.message || this.$t('gifts.validation.errors.unknown'))
    } catch (parseError) {
      console.log('Erreur lors du parsing:', parseError)
      return String(this.$t('gifts.validation.errors.unknown'))
    }
  }
}
