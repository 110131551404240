
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DotationOperation } from '@/models'
import { clipboardSuccess, handleClipboard } from '@/utils/clipboard'

@Component({
  name: 'OperationsDetails'
})
export default class extends Vue {
  @Prop({ required: true }) readonly selectedOperation!: DotationOperation

  private tooltipDelete = false
  private textCSE: Record<string, string> = {
    no_cse: 'Aucun CSE',
    social_cse: 'Social CSE',
    budget_cse: 'Budget CSE'
  }

  get durationYears(): number {
    return this.$moment.duration(this.selectedOperation.dotationDuration).years()
  }

  get durationMonths(): number {
    return this.$moment.duration(this.selectedOperation.dotationDuration).months()
  }

  get durationDays(): number {
    return this.$moment.duration(this.selectedOperation.dotationDuration).days()
  }

  private copyOperationId($event: any): void {
    handleClipboard(this.selectedOperation.id || 'Unknown ID', $event)
  }

  private refresh(): void {
    this.$emit('refresh')
  }

  private editOperation(): void {
    this.$emit('edit')
  }

  private handleDelete(): void {
    this.$emit('delete')
  }
}
