
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'SearchFilters'
})
export default class SearchFilters extends Vue {
  @Prop({ required: true }) readonly headers!: string[]
  @Prop({ required: true }) readonly invalidRowsCount!: number

  private showSearchPopover = false
  private searchInputs: { [key: string]: string } = {}
  private showOnlyErrors = false
  private debounceTimeout: number | null = null

  get hasActiveFilters(): boolean {
    return Object.values(this.searchInputs).some(value => value !== '')
  }

  get activeFiltersCount(): number {
    return Object.values(this.searchInputs).filter(value => value && value.trim() !== '').length
  }

  clearSearch(): void {
    this.searchInputs = {}
    this.showSearchPopover = false
    this.$emit('search', {})
  }

  debounceSearch(header: string): void {
    if (this.debounceTimeout) {
      window.clearTimeout(this.debounceTimeout)
    }
    this.debounceTimeout = window.setTimeout(() => {
      this.$emit('search', { ...this.searchInputs })
    }, 300)
  }
}
