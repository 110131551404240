interface TranslateFunction {
  (key: string, params?: Record<string, any>): string;
  te?: (key: string) => boolean;
}

interface JsonApiError {
  code?: string;
  status?: string;
  title?: string;
  detail?: string;
  source?: {
    pointer?: string;
  };
  meta?: {
    attribute?: string;
    message?: string;
    code?: string;
    line_number?: number;
    details?: string;
  };
}

export function formatErrorMessage(error: string | JsonApiError, t: TranslateFunction): string {
  if (typeof error === 'string') {
    return error;
  }

  try {
    const errorMessage = error.meta?.code || error.meta?.message || error.detail;
    if (!errorMessage) {
      return String(t('gifts.validation.errors.unknown'));
    }

    const [errorCode, lineNumber, jsonDataStr] = errorMessage.split(';');

    if (!errorCode || !lineNumber) {
      return error.detail || error.title || String(t('gifts.validation.errors.unknown'));
    }

    if (errorCode.startsWith('MISSING_')) {
      const field = errorCode.replace('MISSING_', '').toLowerCase();
      return `${t('common.line')} ${lineNumber}: ${t('gifts.validation.errors.fieldRequired', { field })}`;
    }

    switch(errorCode) {
      case 'INVALID_EMAIL_FORMAT':
        return `${t('common.line')} ${lineNumber}: ${t('gifts.validation.errors.invalidEmail')}`;
      case 'MULTI_CHOICE_CARD_NOT_EXIST':
        return `${t('common.line')} ${lineNumber}: ${t('gifts.validation.errors.invalidGiftCard')}`;
      case 'INVALID_AMOUNT':
        return `${t('common.line')} ${lineNumber}: ${t('gifts.validation.errors.invalidAmount')}`;
      case 'INVALID_DATE':
        return `${t('common.line')} ${lineNumber}: ${t('gifts.validation.errors.invalidDate')}`;
      default:
        try {
          const cleanJsonStr = jsonDataStr?.replace(/=>/g, ':');
          const jsonData = cleanJsonStr ? JSON.parse(cleanJsonStr) : null;
          const translationKey = `gifts.validation.errors.${errorCode.toLowerCase()}`;

          if (t.te && t.te(translationKey)) {
            return `${t('common.line')} ${lineNumber}: ${t(translationKey)}`;
          }

          return `${t('common.line')} ${lineNumber}: ${jsonData?.errors || error.detail || error.title || String(t('gifts.validation.errors.unknown'))}`;
        } catch (e) {
          return `${t('common.line')} ${lineNumber}: ${error.detail || error.title || String(t('gifts.validation.errors.unknown'))}`;
        }
    }
  } catch (parseError) {
    console.error('Erreur lors du parsing:', parseError);
    return error.detail || error.title || String(t('gifts.validation.errors.unknown'));
  }
}

export function formatApiErrorResponse(err: any, t: TranslateFunction): string {
  if (err.response?.status === 422) {
    const errorData = err.response.data;
    if (Array.isArray(errorData.errors)) {
      return errorData.errors.map((error: any) => formatErrorMessage(error, t)).join('\n');
    }
    return errorData.error || errorData.message || String(t('gifts.validation.errors.validationWithCode'));
  }

  return err.response?.data?.error ||
         err.response?.data?.message ||
         err.message ||
         String(t('gifts.validation.errors.generic'));
}
