
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Form as ElForm } from 'element-ui/types/element-ui'
import { useUserStore } from '@/stores/user'
import Papa from 'papaparse'
import { DirectUpload } from 'activestorage'
import { MultiChoiceGiftCard, DotationOperation } from '@/models'
import { MessageBox } from 'element-ui'
import { formatApiErrorResponse } from './utils/errorFormatter'

import FileUploadZone from './FileUploadZone.vue'
import CsvFormatInfo from './CsvFormatInfo.vue'
import StatsSummary from './StatsSummary.vue'
import SearchFilters from './SearchFilters.vue'
import DataTable from './DataTable.vue'
import ImportResults from './ImportResults.vue'

interface CsvRow {
  firstname: string;
  lastname: string;
  email: string;
  multi_choice_gift_card: string;
  amount: string;
  scheduled_at: string;
  program: string;
  isValid?: boolean;
  errors?: string[];
  fieldErrors?: { [key: string]: string[] };
  [key: string]: string | boolean | string[] | { [key: string]: string[] } | undefined;
}

interface DotationOperationResponse {
  id: string;
  type: string;
  attributes: {
    id: string;
    success: Array<{
      id: string;
      dotation_operation_id: string;
      amount_cents: number;
      scheduled_at: string;
      [key: string]: any;
    }>;
    fails: Array<{
      row_number: number;
      errors: string[];
    }>;
  };
}

@Component({
  name: 'UploadForm',
  components: {
    FileUploadZone,
    CsvFormatInfo,
    StatsSummary,
    SearchFilters,
    DataTable,
    ImportResults
  }
})
export default class UploadForm extends Vue {
  @Prop({ required: true }) readonly selectedOperation!: DotationOperation;

  private userStore: any = useUserStore();
  private dialogVisible = false;
  private formData = { blob: '' };
  private loading = false;
  private isParsingCsv = false;
  private isUploading = false;
  private csvData: CsvRow[] = [];
  private headers: string[] = [];
  private originalFile: File | null = null;
  private baseUrl: string = process.env.VUE_APP_API_URL || 'http://localhost:80';
  private currentPage = 1;
  private pageSize = 50;
  private temporaryFile: { name: string; raw: File } | null = null;
  private tableLoading = false;
  private searchQuery: { [key: string]: string } = {};
  private showOnlyErrors = false;
  private importResult: DotationOperationResponse | null = null;
  private multiChoiceGiftCards: MultiChoiceGiftCard[] = [];
  private multiChoiceGiftCardsLoading = false;

  private rules = {
    blob: [
      { required: true, message: this.$t('gifts.uploadDialog.fileIsRequired'), trigger: 'blur' }
    ]
  };

  get caretaker() {
    return this.userStore.model.manageable
  }

  async created() {
    await this.getMultiChoiceGiftCards();
  }

  private async getMultiChoiceGiftCards() {
    this.multiChoiceGiftCardsLoading = true;
    try {
      const { data } = await MultiChoiceGiftCard
        .page(1)
        .per(999)
        .all();
      this.multiChoiceGiftCards = data;
    } catch (error) {
      console.error('Erreur lors du chargement des cartes multi-choix:', error);
    } finally {
      this.multiChoiceGiftCardsLoading = false;
    }
  }

  private handleDelete() {
    this.formData.blob = ''
    this.csvData = []
    this.headers = []
    this.originalFile = null
    this.currentPage = 1
  }

  private resetUploader() {
    this.handleDelete()
    this.loading = false
    this.isParsingCsv = false
    this.isUploading = false
    this.temporaryFile = null
    this.tableLoading = false
    this.importResult = null
  }

  private validateRow(row: CsvRow): { field: string; message: string }[] {
    const errors: { field: string; message: string }[] = [];
    const fieldErrors: { [key: string]: string[] } = {};

    ['firstname', 'lastname', 'email', 'multi_choice_gift_card', 'amount'].forEach(field => {
      const value = row[field];
      if (typeof value !== 'string' || !value.trim()) {
        const message = String(this.$t('gifts.validation.errors.fieldRequired', { field }));
        errors.push({
          field,
          message,
        });
        if (!fieldErrors[field]) fieldErrors[field] = [];
        fieldErrors[field].push(message);
      }
    });

    if (row.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row.email)) {
      const message = String(this.$t('gifts.validation.errors.invalidEmail'));
      errors.push({
        field: 'email',
        message
      });
      if (!fieldErrors['email']) fieldErrors['email'] = [];
      fieldErrors['email'].push(message);
    }

    if (row.multi_choice_gift_card && !this.multiChoiceGiftCards.some(card => card.name === row.multi_choice_gift_card)) {
      const message = String(this.$t('gifts.validation.errors.invalidGiftCard'));
      errors.push({
        field: 'multi_choice_gift_card',
        message
      });
      if (!fieldErrors['multi_choice_gift_card']) fieldErrors['multi_choice_gift_card'] = [];
      fieldErrors['multi_choice_gift_card'].push(message);
    }

    if (row.amount && !/^\d+$/.test(row.amount)) {
      const message = String(this.$t('gifts.validation.errors.invalidAmount'));
      errors.push({
        field: 'amount',
        message
      });
      if (!fieldErrors['amount']) fieldErrors['amount'] = [];
      fieldErrors['amount'].push(message);
    }

    if (row.scheduled_at) {
      const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      if (!dateRegex.test(row.scheduled_at)) {
        const message = String(this.$t('gifts.validation.errors.invalidDate'));
        errors.push({
          field: 'scheduled_at',
          message
        });
        if (!fieldErrors['scheduled_at']) fieldErrors['scheduled_at'] = [];
        fieldErrors['scheduled_at'].push(message);
      } else {
        const [day, month, year] = row.scheduled_at.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
          const message = String(this.$t('gifts.validation.errors.invalidDateValue'));
          errors.push({
            field: 'scheduled_at',
            message
          });
          if (!fieldErrors['scheduled_at']) fieldErrors['scheduled_at'] = [];
          fieldErrors['scheduled_at'].push(message);
        }
      }
    }

    row.fieldErrors = fieldErrors;
    return errors;
  }

  private validateAllRows() {
    this.csvData = this.csvData.map(row => {
      const errors = this.validateRow(row);
      return {
        ...row,
        isValid: errors.length === 0,
        errors: errors.map(e => e.message)
      };
    });
  }

  private async parseCSV(file: File) {
    this.isParsingCsv = true;
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results: Papa.ParseResult<CsvRow>) => {
          const requiredHeaders = ['firstname', 'lastname', 'email', 'multi_choice_gift_card', 'amount', 'scheduled_at'];
          const currentHeaders = results.meta.fields || [];

          const missingHeaders = requiredHeaders.filter(header => !currentHeaders.includes(header));

          if (missingHeaders.length > 0) {
            this.isParsingCsv = false;
            reject(new Error(`Le fichier CSV ne contient pas tous les champs requis. Champs manquants : ${missingHeaders.join(', ')}`));
            return;
          }

          this.headers = results.meta.fields || [];
          this.csvData = results.data;
          this.validateAllRows();
          this.isParsingCsv = false;
          resolve(results);
        },
        error: (error: Error) => {
          this.isParsingCsv = false;
          reject(error);
        },
      });
    });
  }

  private async uploadCallback(file: any) {
    try {
      this.isUploading = true

      this.formData.blob = file.blob.signed_id

      const rawFile = file.file.file
      if (rawFile) {
        this.originalFile = rawFile
        this.isParsingCsv = true
        await this.parseCSV(rawFile)
      } else {
        throw new Error('Fichier non trouvé')
      }
    } catch (error: any) {
      this.$notify({
        title: 'Erreur',
        message: error.message || 'Erreur lors de la lecture du fichier CSV',
        type: 'error',
        duration: 5000
      })
      this.handleDelete()
    } finally {
      this.isUploading = false
      this.isParsingCsv = false
    }
  }

  private formatErrorMessage(err: any): string {
    return formatApiErrorResponse(err, this.$t.bind(this));
  }

  async submitForm(): Promise<void> {
    if (this.invalidRowsCount > 0) {
      this.$notify({
        title: String(this.$t('gifts.uploadDialog.notification.error.title')),
        message: String(this.$t('gifts.uploadDialog.errors.formValidation')),
        type: 'error',
        duration: 5000
      })
      return
    }

    (this.$refs.uploadForm as ElForm).validate(async(valid) => {
      if (valid) {
        this.loading = true

        try {
          const modifiedCsvString = this.generateCSV()

          if (!this.csvData.length) {
            throw new Error(String(this.$t('gifts.uploadDialog.errors.noData')))
          }

          const modifiedBlob = new Blob([modifiedCsvString], {
            type: 'text/csv;charset=utf-8;'
          })

          const modifiedFile = new File([modifiedBlob],
            this.originalFile?.name || 'modified.csv',
            { type: 'text/csv' }
          )

          const upload = new DirectUpload(
            modifiedFile,
            `${this.baseUrl}/rails/active_storage/direct_uploads`,
            {
              directUploadWillCreateBlobWithXHR: (xhr) => {
                xhr.setRequestHeader('Authorization', `Bearer ${this.userStore.user.access_token}`)
              }
            }
          )

          upload.create(async (error, blob) => {
            if (error) {
              throw error
            }

            try {
              const response = await this.selectedOperation.giveGifts(blob.signed_id)
              this.importResult = response

              this.loading = false
              this.$emit('uploaded')
              this.$emit('refresh')
            } catch (err: any) {
              this.loading = false
              const errorMessage = this.formatErrorMessage(err)
              this.$notify({
                title: String(this.$t('gifts.uploadDialog.notification.error.title')),
                message: errorMessage,
                type: 'error',
                duration: 10000,
                dangerouslyUseHTMLString: true
              })
            }
          })
        } catch (err: any) {
          this.loading = false
          const errorMessage = this.formatErrorMessage(err)
          this.$notify({
            title: String(this.$t('gifts.uploadDialog.notification.error.title')),
            message: errorMessage,
            type: 'error',
            duration: 10000,
            dangerouslyUseHTMLString: true
          })
        }
      }
    })
  }

  private generateCSV(): string {
    const cleanData = this.csvData
      .filter(row => Object.values(row).some(value => value !== ''))
      .map(row => {
        const { fieldErrors, isValid, errors, ...cleanRow } = row;
        return cleanRow;
      });

    const csvString = Papa.unparse(cleanData, {
      header: true
    })
    return csvString.replace(/,/g, ';')
  }

  private deleteRow(index: number) {
    this.tableLoading = true
    const realIndex = this.csvData.findIndex(row => row === this.filteredCsvData[index])
    if (realIndex !== -1) {
      this.csvData.splice(realIndex, 1)
    }
    this.$nextTick(() => {
      this.tableLoading = false
    })
  }

  private downloadCSV() {
    const csvString = this.generateCSV()
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = this.originalFile?.name?.replace('.csv', '_modified.csv') || 'modified.csv'
    link.click()
    window.URL.revokeObjectURL(link.href)
  }

  private handleCurrentChange(page: number) {
    this.tableLoading = true
    this.currentPage = page
    this.$nextTick(() => {
      this.tableLoading = false
    })
  }

  private handleSearch(query: { [key: string]: string }) {
    this.searchQuery = query
    this.currentPage = 1
  }

  private handleToggleErrors(value: boolean) {
    this.showOnlyErrors = value
    this.currentPage = 1
  }

  private handleCellEdit({ index, field, value }: { index: number; field: string; value: any }) {
    this.tableLoading = true
    const realIndex = this.csvData.findIndex(row => row === this.filteredCsvData[index])
    if (realIndex !== -1) {
      this.csvData[realIndex][field] = value
      const errors = this.validateRow(this.csvData[realIndex])
      this.csvData[realIndex].isValid = errors.length === 0
      this.csvData[realIndex].errors = errors.map(e => e.message)
    }
    this.$nextTick(() => {
      this.tableLoading = false
    })
  }

  get filteredCsvData(): CsvRow[] {
    return this.csvData.filter(row => {
      if (this.showOnlyErrors && row.isValid) {
        return false
      }

      return Object.entries(this.searchQuery).every(([key, value]) => {
        if (!value) return true
        const cellValue = String(row[key] || '').toLowerCase().trim()
        const searchValue = value.toLowerCase().trim()
        return cellValue.includes(searchValue)
      })
    })
  }

  get invalidRowsCount(): number {
    return this.csvData.filter(row => !row.isValid).length
  }

  get totalAmount(): number {
    return this.csvData.reduce((total, row) => {
      const amount = parseInt(row.amount) || 0
      return total + amount
    }, 0)
  }

  get successCount(): number {
    return this.importResult?.attributes?.success?.length || 0
  }

  get failsCount(): number {
    return this.importResult?.attributes?.fails?.length || 0
  }

  get fails(): Array<{ row_number: number; errors: string[] }> {
    return this.importResult?.attributes?.fails || []
  }

  private handleDialogClose() {
    this.dialogVisible = false
    this.resetUploader()
    this.importResult = null
  }

  private async confirmAndSubmit() {
    try {
      await MessageBox.confirm(
        String(this.$t('gifts.uploadDialog.confirmation.subtitle')),
        String(this.$t('gifts.uploadDialog.confirmation.title')),
        {
          confirmButtonText: String(this.$t('gifts.uploadDialog.confirmation.confirm')),
          cancelButtonText: String(this.$t('gifts.uploadDialog.confirmation.cancel')),
          type: 'warning'
        }
      )
      this.submitForm()
    } catch {
      // L'utilisateur a annulé
    }
  }
}
