
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'HistoryFundsTableFilters'
})
export default class extends Vue {
  filters: any = {
    email: null,
    firstName: null,
    lastName: null,
    dateRange: null,
    sourceType: null
  }
  pagination = {
    page: 1,
    limit: 10
  }

  sourceTypes = [
    { label: 'Virement bancaire', value: 'BankTransfer' },
    { label: 'Dotation multi-choix', value: 'MultiChoiceCard' }
  ]

  handleSearch() {
    this.$emit('filters', this.filters)
  }
}
