
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DotationOperation } from '@/models'
import { Form } from 'element-ui'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'OperationDialog',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    MultiChoiceCardDistributionsForm: () => import('@/components/MultiChoiceCardDistributionsForm/index.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: false, default: null }) private operation!: any

  userStore: any = useUserStore()

  private tempData: DotationOperation = new DotationOperation({
    caretaker: this.caretaker,
    dotationDuration: 'P1Y1M1D',
    cseKind: 'no_cse',
    preIssue: false
  })

  private loading = false

  private dialogStatus = 'create'
  private textMap: any = {
    update: 'Mise à jour d\'une opération',
    create: 'Création d\'une opération'
  }

  rules: any = {
    donorName: [
      { required: true, message: 'Le nom du donneur est requis', trigger: 'blur' }
    ],
    identifier: [
      { required: true, message: 'L\'identifiant de l\'opération est requise', trigger: 'blur' }
    ],
    maximumAmount: [
      { required: true, message: 'Le montant maximum de l\'opération est requise', trigger: 'blur' }
    ],
    "multiChoiceGiftCard.id": [
      { required: true, message: 'Le choix de la dotation multi-choix est requis', trigger: 'blur' }
    ],
    dotationDuration: [
      { required: true, message: 'La durée de validité est requise', trigger: 'blur' }
    ],
    startAt: [
      { required: true, message: 'La date de début est requise', trigger: 'blur' }
    ],
    endAt: [
      { required: true, message: 'La date de fin est requise', trigger: 'blur' }
    ],
    donorLogo: [
      { required: true, message: 'Le logo du donneur est requis', trigger: 'blur' }
    ],
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      !this.operation ? this.handleCreate() : this.handleUpdate()
    }
  }

  get caretaker() {
    return this.userStore.model.manageable
  }

  get durationYears() {
    return this.$moment.duration(this.tempData.dotationDuration).years()
  }

  set durationYears(v: any) {
    const years = this.$moment.duration(v, 'y')
    const months = this.$moment.duration(this.durationMonths, 'M')
    const days = this.$moment.duration(this.durationDays, 'd')

    this.tempData.dotationDuration = years.add(months).add(days).toISOString()
  }

  get durationMonths() {
    return this.$moment.duration(this.tempData.dotationDuration).months()
  }

  set durationMonths(v: any) {
    const years = this.$moment.duration(this.durationYears, 'y')
    const months = this.$moment.duration(v, 'M')
    const days = this.$moment.duration(this.durationDays, 'd')

    this.tempData.dotationDuration = years.add(months).add(days).toISOString()
  }

  get durationDays() {
    return this.$moment.duration(this.tempData.dotationDuration).days()
  }

  set durationDays(v: any) {
    const years = this.$moment.duration(this.durationYears, 'y')
    const months = this.$moment.duration(this.durationMonths, 'M')
    const days = this.$moment.duration(v, 'd')

    this.tempData.dotationDuration = years.add(months).add(days).toISOString()
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      (this.$refs.operationForm as Form).clearValidate()
    })
  }

  private handleUpdate() {
    this.tempData = this.operation.dup()

    this.dialogStatus = 'update'
    this.$nextTick(() => {
      (this.$refs.operationForm as Form).clearValidate()
    })
  }

  createOrUpdate() {
    (this.$refs.operationForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {
        const data: DotationOperation = this.tempData

        try {
          await data.save({ with: ['caretaker.id', 'multiChoiceGiftCard.id']})

          if (this.dialogStatus === 'create') {
            this.$emit('created', data)
            this.$notify({
              title: 'DotationOperation Created',
              message: 'DotationOperation successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', data)
            this.$notify({
              title: 'DotationOperation Updated',
              message: 'DotationOperation successfully updated',
              type: 'success',
              duration: 2000
            })
          }
          this.resetTempData()
          this.$emit('close')
        } catch (err) {
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  resetTempData() {
    this.tempData = new DotationOperation({
      caretaker: this.caretaker,
      dotationDuration: 'P1Y1M1D',
      cseKind: 'no_cse',
      preIssue: false
    })
  }

  uploadCallback(file: any) {
    this.tempData.donorLogo = file.blob.signed_id
  }

  uploadCallbackBanner(file: any) {
    this.tempData.bannerImage = file.blob.signed_id
  }

  private formatMaximumAmountTooltip(amount: number) {
    return this.$n(amount, 'currency')
  }
}
