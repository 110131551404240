
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'StatsSummary'
})
export default class StatsSummary extends Vue {
  @Prop({ required: true }) readonly totalRows!: number
  @Prop({ required: true }) readonly totalAmount!: number

  get formattedTotalAmount(): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(this.totalAmount)
  }
}
