
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MultiChoiceCard, DotationOperation } from '@/models'
import moment from 'moment'

import Pagination from '@/components/Pagination/index.vue'
import { clipboardSuccess } from '@/utils/clipboard'

interface TableRow {
  row: MultiChoiceCard;
}

@Component({
  name: 'MultiChoiceCardsTable',
  components: {
    Pagination,
    MultiChoiceCardDialog: () => import('./MultiChoiceCardDialog.vue'),
    FiltersTable: () => import('./FiltersTable.vue'),
    DotationCampaignsUploadForm: () => import('./UploadForm/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) readonly selectedOperation!: DotationOperation
  @Prop({ required: true }) private operation!: DotationOperation

  multiChoiceCards: MultiChoiceCard[] = []
  totalMultiChoiceCards = 0
  multiChoiceCardsLoading = false

  showMultiChoiceCardDialog = false
  clipboardSuccess = clipboardSuccess

  filters: any = {
    email: null,
    firstName: null,
    lastName: null,
    dateRange: null,
    amount: null
  }
  private pagination = {
    page: 1,
    limit: 20
  }

  formatDate(date: string | Date): string {
    const formattedDate = moment(date).format('L')
    return formattedDate === 'Invalid date' ? '' : formattedDate
  }

  @Watch('operation', { immediate: true })
  async getMultiChoiceCards() {
    this.multiChoiceCardsLoading = true

    const createdAtGte = this.filters.dateRange ? moment(String(this.filters.dateRange![0])).format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.filters.dateRange ? moment(String(this.filters.dateRange![1])).format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } =
      await MultiChoiceCard
        .includes(['customer', 'multiChoiceGiftCard'])
        .where({
          dotationOperationId: this.operation.id,
          customerEmail: { prefix: this.filters.email },
          customerFirstname: { prefix: this.filters.firstName },
          customerLastname: { prefix: this.filters.lastName },
          amountCents: this.filters.amount ? this.filters.amount * 100 : null,
          createdAt: { gte: createdAtGte, lte: createdAtLte }
        })
        .page(this.pagination.page)
        .per(this.pagination.limit)
        .order({ createdAt: 'desc' })
        .stats({ total: 'count' })
        .all()

    if (data.length === 0 && this.pagination.page > 1) this.pagination.page -= 1
    else this.multiChoiceCards = data

    this.multiChoiceCardsLoading = false
    this.totalMultiChoiceCards = meta.stats.total.count
  }

  handleSearch(filters: any): void {
    this.filters = filters
    this.pagination.page = 1
    this.multiChoiceCards = []
    this.getMultiChoiceCards()
  }

  truncateID(id: string): string {
    return `${id.slice(0, 4)} .... ${id.substr(id.length - 4)}`
  }

  addInList(multiChoiceCard: MultiChoiceCard): void {
    this.multiChoiceCards.unshift(multiChoiceCard)
  }
}
