
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MultiChoiceGiftCard } from '@/models'

interface CsvRow {
  [key: string]: any;
  isValid?: boolean;
  fieldErrors?: { [key: string]: string[] };
}

@Component({
  name: 'DataTable'
})
export default class DataTable extends Vue {
  @Prop({ required: true }) readonly data!: CsvRow[]
  @Prop({ required: true }) readonly headers!: string[]
  @Prop({ required: true }) readonly loading!: boolean
  @Prop({ required: true }) readonly multiChoiceGiftCards!: MultiChoiceGiftCard[]
  @Prop({ required: true }) readonly multiChoiceGiftCardsLoading!: boolean
  @Prop({ required: true }) readonly currentPage!: number
  @Prop({ required: true }) readonly pageSize!: number

  get paginatedData(): CsvRow[] {
    const start = (this.currentPage - 1) * this.pageSize
    const end = this.currentPage * this.pageSize
    return this.data.slice(start, end)
  }

  handleCurrentChange(page: number): void {
    this.$emit('page-change', page)
  }

  handleCellEdit(index: number, field: string, value: any): void {
    this.$emit('cell-edit', { index, field, value })
  }

  hasErrorForField(row: CsvRow, field: string): boolean {
    return row.fieldErrors && row.fieldErrors[field] && row.fieldErrors[field].length > 0
  }

  getErrorsForField(row: CsvRow, field: string): string[] {
    return row.fieldErrors && row.fieldErrors[field] ? row.fieldErrors[field] : []
  }

  getCellStyle({ row, column }: { row: CsvRow; column: any }): object {
    if (column.label === 'Actions') {
      return {}
    }

    const value = row[column.property]
    const isEmpty = !value || (typeof value === 'string' && value.trim() === '')

    if (column.property === 'multi_choice_gift_card' && value) {
      const isValidCard = this.multiChoiceGiftCards.some(card => card.name === value)
      if (!isValidCard) {
        return {
          backgroundColor: '#FFF5F5',
          color: '#F56C6C'
        }
      }
    }

    if (isEmpty || this.hasErrorForField(row, column.property)) {
      return {
        backgroundColor: '#FFF5F5',
        color: '#F56C6C'
      }
    }
    return {}
  }

  getRowClass(row: { row: CsvRow }): string {
    return row.row.isValid ? '' : 'invalid-row'
  }
}
